import { MenuList, MenuItem, Paper, Stack, Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import BookIcon from "@mui/icons-material/Book";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

export const MainMenu = () => {
  const [theme, setTheme] = useState("light");
  const [themeWasSet, setThemeWasSet] = useState(false);


  const navigate = useNavigate();

  const handleSetThemeMode = (themeMode) => {
    
    updateLocalStorage(themeMode);
  };

  const updateLocalStorage = (newMode) => {
    setTheme(newMode);
    localStorage.setItem("themeMode", newMode);

    setThemeWasSet(true);
    window.location.pathname = '';
  };

  // useEffect(() => {
  //   if (themeWasSet){
  //     setTheme(theme === "light" ? "light" : "dark");
  //   }


  //   // console.warn(`theme is ${theme}`);
  // }, [theme]);

  return (
    <Stack className="menu-bg" spacing={3}>
      {/* <Paper
      className="menu-bg"
        style={{
          backgroundColor: "#061C31",
          color: "#FFF",
          marginLeft: "50px",
          borderRadius: "15px",
          width: "100%",
          maxWidth: "70px",
        }}
      > */}
        <MenuList className="techni-menu">
          <MenuItem onClick={() => navigate("/home")} title="Home">
            <HomeIcon />
          </MenuItem>
          <MenuItem onClick={() => navigate("/services")} title="Services">
            <HubIcon />
          </MenuItem>
          <MenuItem onClick={() => navigate("/blog")} title="Blog">
            <BookIcon />
          </MenuItem>
          <MenuItem onClick={() => navigate("/contact")} title="Contact Us">
            <ConnectWithoutContactIcon />
          </MenuItem>
          <Divider light flexItem orientation="vertical" variant="middle" />
          <MenuItem
            onClick={() => handleSetThemeMode("light")}
            title="Light Mode"
          >
            <LightModeIcon />
          </MenuItem>
          <MenuItem
            onClick={() => handleSetThemeMode("dark")}
            title="Dark Mode"
          >
            <DarkModeIcon />
          </MenuItem>
        </MenuList>
      {/* </Paper> */}
    </Stack>
  );
};
