import React from "react";
import "../css/Portfolio.css";

import { Typography, Popover } from "@mui/material";

export const Portfolio = () => {
  // function handleDisplayDetails(child: number) {

  // 	document.querySelector(`.portfolio-container .wrapper .card-details:nth-child(${child})`)?.setAttribute('style', 'display: block');
  // }

 // const netCoreHeader = "Recent projects built using .NETCore";

  const netCore = (
    <div className="card-details">
      <h2 className="text-uppercase">Projects built using .NETCore</h2>
      <div  className="item-intro text-muted">
       
          Honing in on .NETCore's portability and ability to be used on many
          platforms
      
      </div>
      <div>
        <ul className="list">
          <li>.NETCore React single page static content website</li>
          <li>
            .NETCore Windows service that monitors a folder and performs certain
            tasks based on contents
          </li>
          <li>
            .NETCore Console app that accepts user input to perform various
            tasks
          </li>
          <li>
            .NETCore WebApi project provides a secure manner in which clients
            can access their data, great for reporting or sharing data between multiple applications
          </li>
          <li>
            .NETCore OpenApi 3.0 project that generates the client side code and
            provides a UI to access the meta data for associated WebApi related
            projects
          </li>
          <li>Retrieval of data from NoSQL database like Azure Cosmos DB as well as MongoDB in addition to MSSQL</li>
        </ul>
      </div>
    </div>
  );

  const webApi = (
    <div className="card-details">
      <h2 className="text-uppercase">WebApi implementations</h2>
      <div  className="item-intro text-muted">
       
          Using WebApi to provide a secure manner for clients to access and utilize their
          data, useful when custom reports or when there is a need to analyze data for business intelligence
    
      </div>
      <div>
        <ul className="list">
          <li>
            OpenApi 3.0 project that provides the client with access to their data via
            a UI accessible by the browser, enables access to the meta data for associated WebApi related projects
          </li>
          <li>
            WebApi project generates a unique scoring system in which the client is able to derive specific details about their clientele
          </li>
          <li>
            A secure means of funneling data between multiple siloed applications
          </li>
        </ul>
      </div>
    </div>
  );

  const react = (
    <div className="card-details">
      <h2 className="text-uppercase">Projects developed using React</h2>
      <div  className="item-intro text-muted">
    
          There is no doubt that React is fast! Pair it with a .NETCore WebApi
          back end and your imagination is the limit.
  
      </div>
      <ul className="list">
        <li>React & .NETCore single page static content website, for businesses that need interactivity with a database</li>
        <li>Wordpress React single page website, great for small businesses that need a web presence</li>
        <li>React & Wordpress chat Gutenberg block</li>
        <li>React & Wordpress whiteboard Gutenberg block</li>
        <li>React & Wordpress video conference Gutenberg block</li>
        <li>React & Wordpress custom authentication Gutenberg block</li>
      </ul>
    </div>
  );

  const frontEnd = (
    <div className="card-details">
      <h2 className="text-uppercase">Front-end projects</h2>
      <div  className="item-intro text-muted">
        
          React that leverages Typescript which allows for better control over the code where data models and classes are heavily used to create a feature rich user experience.  Blazor and .NET Maui used to provide the user with the ability to connect with other users that have similar interests.
     
      </div>
      <ul className="list">
        <li>Single Sign On (SSO) Authentication using many of the popular social media websites</li>
        <li>User profiles, badges, messaging</li>
        <li>Creation of projects, attaching notes, documents to name of few</li>
        <li>React with a Ruby On Rails API that accesses a Postgres SQL database</li>
        <li>Provide the user with a shared experience enabling them to interact via browser, Android or IOS by utilizing Blazor and .net Maui with a MSSQL database</li>
      </ul>
    </div>
  );

  const customSolutions = (
    <div className="card-details">
      <h2 className="text-uppercase">Custom Solutions</h2>
      <div  className="item-intro text-muted">
        
          Custom solutions created for each client based on their specific
          needs.
      
      </div>
      <ul className="list">
        <li>
          PHP SSO bridge to facilitate secure handoff and authenticate protocols
        </li>
        <li>Update and create custom modules for CMS .NetNuke platform</li>
        <li>Architect and develop a social media IOS App using Xamarin</li>
        <li>Architect and develop a music sharing IOS App using Xamarin</li>
      </ul>
    </div>
  );

  const BI = (
    <div className="card-details">
      <h2 className="text-uppercase">Business Intelligence Solutions</h2>
      <div  className="item-intro text-muted">     
          BI solutions tailored to client specific business requirements.
      </div>
      <ul className="list">
        <li>Customized Integration and Delivery CI/CD Solutions</li>
        <li>Diagramming and Data Analysis useful to connect multiple systems and/or simplify complex interconnected processes</li>
        <li>Developed a unique scoring system based on dynamic attributes and weights</li>
        <li>Created a custom workflow using RabbitMQ used in tracking the flow of data through multiple departments.  Used in reporting for KPIs.</li>
      </ul>
    </div>
  );

  return (
    <div className="portfolio-container">
      <MouseOverPopover element={netCore} />

      <MouseOverPopover element={webApi} />

      <MouseOverPopover element={react} />

      <MouseOverPopover element={frontEnd} />

      <MouseOverPopover element={customSolutions} />

      <MouseOverPopover element={BI} />
    </div>
  );
};

export default function MouseOverPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const headerToDisplay = props.header;
  const textToDisplay = props.element;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="wrapper">
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <div className="card"></div>
        {headerToDisplay}
      </Typography>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{textToDisplay}</Typography>
      </Popover>
    </div>
  );
}
