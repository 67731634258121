import React from "react";

const Blog = () => {
  return (
    <div>
      <h1>Blog</h1>
      <iframe src="http://blog.technificentconsulting.com" title="Technificent Consulting - Digibabble Blog" />
    </div>
  );
};

export default Blog;
