import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { Layout } from './Layout';
import { BrowserRouter } from 'react-router-dom';

const currentTheme  =  localStorage.getItem('themeMode') === null ? 'light' :  localStorage.getItem('themeMode' ) ;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <Layout themeMode={currentTheme}>
       <App />
   
 </Layout>
 </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
