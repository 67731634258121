import React from "react";
import { MainMenu } from "./main/features/MainMenu";
import logo from "./assets/logo.png";
import Container from "@mui/material/Container/Container";

import "./css/App.css";


console.warn(`env in use is ${process.env.NODE_ENV}`)

export const Layout = ({ children, themeMode }) => {

  const currentYear = new Date().getFullYear();
 
  return (
    <div
      className={themeMode}
      style={{
        
        flexFlow: "column",
        display: "flex",
        alignContent: "center",
      }}

    > 
      <header className="App-header">
        <a href="/">
          <img src={logo} alt="logo" />
        </a>
      </header>

      <div style={{ display: 'flex' }}>
      <MainMenu />
        <Container
          id="container-main"
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div id="children" className="col-md-12">        
         
            {children}
          </div>
        </Container>
      </div>

      <footer
        className="col-md-12 footer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
       
          textAlign: "center"
        }}
      >
        <div className="col-md-4">
          Copyright © Technificent Consulting {currentYear}
        </div>
        <div className="col-md-4" style={{  padding: '0 20px 0 0' }}>
          <a href="https://www.generateprivacypolicy.com/live.php?token=NOnrp9FF0KO9uDxAf6MWzb8BltS9sl61">Privacy Policy</a>  <a href="https://www.terms-conditions-generator.com/live.php?token=lZUr8kOcx4f4wxFxDw95GH5Na6xsfklh">Terms of Use</a>
        </div>
      </footer>

      {/* <div className="parallaxbg"> </div>  */}
    </div>
  );
};
