import React, { FormEvent, useState } from "react";
import Axios from "axios";
import { TextField } from "@mui/material";


const HOST = process.env.REACT_APP_API;

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    from: "",
    email: "",
    message: "",
    phone: "",
    subject: "Inquiry from Technificent Consulting - Contact",
  });
  const axios = Axios;

  const [readyToSend, setReadyToSend] = useState(false);

  const [showMessage, setShowMessage] = useState("");
  const successfulResponse = "Submitted.  Thank you for reaching out!";
  const errorResponse =
    "Oops we were unable to send your message.  Please try again.";
  const missingRequired = "Oops! Required fields are not filled in.";
  const invalidEmail = "Invalid email address detected.";
  const invalidPhone = "Invalid phone number format detected.";

  const onChangeFrom = (event: any) => {
    contactInfo.from = event.target.value;
    setContactInfo(contactInfo);
  };

  const onChangeEmail = (event: any) => {
    contactInfo.email = event.target.value;
    setContactInfo(contactInfo);
  };

  const onChangeMessage = (event: any) => {
    contactInfo.message = event.target.value;
    setContactInfo(contactInfo);
  };

  const onChangePhone = (event: any) => {
    contactInfo.phone = event.target.value;

    const cleaned = `${contactInfo.phone }`.replace(/\D/g, "");
 
      const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = match[1] ? "+1 " : "";
        var formattedPhone = [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
        contactInfo.phone = formattedPhone;
      }

      
    setContactInfo(contactInfo);
  };

  const onMouseUpPhone = (event: any) => {
    contactInfo.phone = event.target.value;

    if (event.target.value !== "") {

      const updatedMessage =
        contactInfo.message + " Contact phone: " + contactInfo.phone;
      contactInfo.message = updatedMessage;
      setContactInfo(contactInfo);
    }
  };

  const checkRequired = (event: any) => {
    event.preventDefault();
    let msg = "";

    if (contactInfo.email.indexOf("@") === 0) {
      msg = invalidEmail;
      setShowMessage(msg);
    } else if (
      contactInfo.phone.replace("-", "").length < 11 ||
      typeof contactInfo.phone.replace("-", "") !== typeof Number
    ) {
      msg = invalidPhone;
      setShowMessage(msg);
    } else {
      msg = "";
      setShowMessage(msg);
    }

    if (contactInfo.email === "" || contactInfo.message === "") {
      msg = missingRequired;
      setShowMessage(msg);
    } else {
      msg = "";
      setShowMessage(msg);
    }

    if (msg === "") {
      setReadyToSend(true);
    }
  };

  const sendEmail = async (event: FormEvent) => {
    event.preventDefault();

    if (readyToSend) {
      await axios
        .post(`${HOST}/api/smtp`, {
          body: contactInfo,
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((resp) => {
          if (resp.status) {
            setContactInfo({});
            setShowMessage(successfulResponse);
          } else {
            setShowMessage(errorResponse);
          }

          return resp;
        })
        .catch((ex) => {
          setShowMessage(errorResponse);
        });
    } else {
      setShowMessage(missingRequired);
    }
  };

  return (
    <div>
      <h1>Contact</h1>
      <div className="contact-container">
        <div>
          <div className="col-lg-12 text-center">
            <h3 className="section-subheading text-muted">
              Reach out and we'll respond within 24 - 48 hours.
            </h3>
          </div>
        </div>
        <div>
          <div className="col-lg-12">
            <form id="contactForm" name="sentMessage" noValidate={true}>
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <TextField
                      required
                      name="full-name"
                      label="Your Name"
                      defaultValue=""
                      variant="filled"
                      className="form-control"
                      onChange={(event) => onChangeFrom(event)}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      required
                      name="email"
                      label="Email"
                      defaultValue=""
                      variant="filled"
                      className="form-control"
                      onChange={(event) => onChangeEmail(event)}
                    />
                  </div>
                  <div className="form-group">
                    <TextField
                      required
                      name="tel"
                      label="Phone"
                      type="tel"
                      defaultValue={contactInfo.phone}
                      placeholder="111-111-1111"
                      variant="filled"
                      className="form-control"
                      role="textbox"
                      onMouseUp={(event) => onMouseUpPhone(event)}
                      onChange={(event) => onChangePhone(event)}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="form-group">
                    <TextField
                      required
                      label="Your Message"
                      defaultValue=""
                      variant="filled"
                      className="form-control"
                      multiline
                      rows={8}
                      onChange={(event) => onChangeMessage(event)}
                    />
                  </div>
                </div>

                <div className="col-lg-12 text-center">
                  <div>{showMessage}</div>
                  <button
                    id="sendMessageButton"
                    className="btn btn-contact btn-xl text-uppercase"
                    type="submit"
                    onMouseOver={(event) => checkRequired(event)}
                    onClick={(event) => sendEmail(event)}
                  >
                    Send Message
                  </button>
                  {/* <div>
                    <Captcha
                      captchaStyleName="contactCaptcha"
                      ref={(captcha) => {
                        captcha = Captcha;
                      }}
                    />
                    {Captcha}
                    <input
                      id="contactCaptchaInput"
                      type="text"
                      style={{ display: "none" }}
                    />
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
