import React from "react";

import BeginningsPic from "../assets/about/beginnings.png";
import FuturePic from "../assets/TC2023.svg";
import BornPic from "../assets/about/born.jpg";
import BuzzPic from "../assets/about/buzz.png";
import WingsPic from "../assets/about/wings.jpg";
import OwnerPic from "../assets/about/owner.jpg";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import StackOverflowIcon from "../assets/stackoverflow.svg";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  function About() {
    return (
      <div style={{ fontSize: "36px", padding: "0 0" }}>
        <div style={{ width: "100%", fontSize: "1.2rem" }}>
          <div
          className="team-member-title"
          >
            <div
              className="col-12"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div className="team-member box">
                <img className="rounded-circle" src={OwnerPic} alt="" />

                <a href=" " onClick={() => navigate("/services/resume")}>
                  View Resume
                </a>
              </div>
              <div
                className="col-12"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h4>
                  Arminda Colón
                  <p className="text-muted">Founder / Lead Developer</p>
                </h4>
              </div>
              <div>
                <ul className="list-inline social-buttons">
                  <li className="list-inline-item">
                    <a
                      href="https://linkedin.com/in/technificentconsulting"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <LinkedInIcon sx={{ color: "#FFF" }} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://github.com/technificentconsulting"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <GitHubIcon sx={{ color: "#FFF" }} />
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://stackoverflow.com/users/7422426/technified"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={StackOverflowIcon}
                        alt="Stack Overflow"
                        style={{ border: "none", height: "24px" }}
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-12"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <h1 className="text-bold">
                I love what I do and this is how I share it with you.
              </h1>

              <span>
           
                <p>
                  Using my passion for puzzles and technology to help businesses
                  grow and expand, I've created a process where I take your
                  dreams and visions and make them a reality.
                </p>
                <p>
           
                  From one business owner to another, I understand the needs of
                  a small business trying to expand and while the ever growing
                  world of technology can be both a gift as well as a hindrance
                  you need someone that is skilled and someone that you can
                  trust.
                </p>
                <p>
                  When you hire us for the job, you can expect to receive a well
                  rounded approach to your business needs, performed by someone
                  with a lot of business knowledge and many successful projects.
                </p>
                <p>
                  Let us help you make the most of the gifts that technology has
                  to offer, we understand all of the pieces of the puzzle!
                </p>
                <p>
                  Looking forward to working with you ~ <em>Arminda</em>
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // async function ouptput(download: boolean) {
  //   var pdf: jsPDF = new jsPDF({
  //     unit: "px",
  //     format: "a4",
  //     orientation: "landscape",
  //   });

  //   //  await generatePDF().then((l) =>{
  //   //   console.log('end');
  //   //   console.log(l);
  //   // });

  //   await generatePDF()
  //     .then((img) => {
  //       console.log(img);

  //       return img;
  //     })
  //     .then((img) => {
  //       let canvasImg = document.getElementById(
  //         "#testcanvas"
  //       ) as HTMLImageElement;

  //       //canvasImg.src = img;
  //       //console.log(img);

  //       //   pdf.html(printContainer, {
  //       //    callback: (data) => {
  //       //     if (download) {
  //       //       data.save("test.pdf");
  //       //     } else {
  //       //       data.output("dataurlnewwindow");
  //       //     }
  //       //   },
  //       // });
  //     });
  // }

  // async function generatePDF() {
  //   return ( elements.map(async (element) =>  {
  //     const canvas = await html2canvas(element, {
  //       scale: 2,
  //       allowTaint: true,
  //       x: 0,
  //     });

  //     element.replaceWith(canvas);

  //   })
  //   );
  // }

  return (
    <div>
      {About()}
      <ul className="col-md-12 timeline">
        <li>
          <div className="timeline-image">
            <img
              className="rounded-circle img-fluid"
              src={BeginningsPic}
              alt="HumbleBeginnings"
            />
          </div>
          <div className="timeline-panel left">
            <div className="timeline-heading">
              <h4>2009-2011</h4>
              <h4 className="subheading">Humble Beginnings</h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted">
                As a youth I was always taking things apart and trying to fix
                them which is when I started to learn about my passion to learn,
                understand and answer the questions why, how and can I make it
                better.
              </p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image">
            <img
              className="rounded-circle img-fluid"
              src={BornPic}
              alt="TechnificentConsultingWasBorn"
            />
          </div>
          <div className="timeline-panel right">
            <div className="timeline-heading">
              <h4>January 2014</h4>
              <h4
                className="subheading"
                style={{ textOverflow: "ellipsis", overflow: "hidden" }}
              >
                Technificent Consulting was Born
              </h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted">
                I started out with a passion to create, the love of making an
                idea a reality and the hope that I could help create some really
                amazing things!
              </p>
            </div>
          </div>
        </li>
        <li>
          <div className="timeline-image">
            <img
              className="rounded-circle img-fluid"
              src={BuzzPic}
              alt="MakingABuzz"
            />
          </div>
          <div className="timeline-panel left">
            <div className="timeline-heading">
              <h4>September 2016</h4>
              <h4 className="subheading">Making a Buzz</h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted">
                Sometimes word of mouth is all you need! When a local business
                owner was in need of support for a product that was no longer
                supported, Technificent Consulting was given as a reference.
                From that point forward there was a little buzz going on locally
                that we could provide a solution for your needs.
              </p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image">
            <img
              className="rounded-circle img-fluid"
              src={WingsPic}
              alt="SpreadingOurWings"
            />
          </div>
          <div className="timeline-panel right">
            <div className="timeline-heading">
              <h4>July 2018</h4>
              <h4 className="subheading">Spreading Our Wings</h4>
            </div>
            <div className="timeline-body">
              <p className="text-muted right">
                At some point in your career everything starts to make sense,
                your job becomes almost effortless and then you spread your
                wings. Becoming a remote consultant was one of the best
                decisions I could have made. Not only do I get to work with
                companies all over the world but I also get to dabble in a lot
                of the latest tech.
              </p>
            </div>
          </div>
        </li>
        <li className="timeline-panel">
          <div className="timeline-image">
            <img
              className="rounded-circle img-fluid dive-deep"
              src={FuturePic}
              alt="MaturityIntoTheFuture"
            />
          </div>
          <div className="timeline-panel left">
            <div className="timeline-heading">
              <h4>May 2022</h4>
              <h4 className="subheading">Maturity & The Future</h4>
            </div>
            <div className="timeline-body ">
              <p className="text-muted">
                As AI and data analysis become central to any businesses growth,
                our knowledge in the following areas have followed suit. Dev Ops
                has become a key to increasing efficiency by utilizing CI/CD,
                cloud based platforms, SASS solutions and data lakes to provide
                the intelligent scalability needed to architect secure front and
                back end applications.
              </p>
            </div>
          </div>
        </li>
        <li className="timeline-inverted">
          <div className="timeline-image text-bubble">
            <h4>
              Become Part
              <br />
              Of Our
              <br />
              Story!
            </h4>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Home;
