import React from "react";

import { Routes, Route } from "react-router-dom";

import Home from "./main/Home";
import Contact from "./main/Contact";
import Blog from "./main/Blog";
import Services from "./main/Services";

const App = () => (
  <Routes>
    <Route path="*" element={<Home />} />
    <Route path="home" element={<Home />} />
    <Route path="contact" element={<Contact />} />
    <Route path="blog" element={<Blog />} />
    <Route exact path="services/resume" element={<Services displayResume='true'/>}  />
    <Route path="services"  element={<Services  displayResume='false'/>} />
  </Routes>
);

export default App;
