import React from "react";

import resume from "../assets/about/Resume_2023.pdf";
import { Portfolio } from "./Portfolio";
import { Stack } from "@mui/material";

const Services = (props) => {
  const routeProps = props;
  const displayResume = routeProps.displayResume === "true";

/*

  const printContainer = document.querySelector(
    ".printcontainer"
  ) as HTMLDivElement;

  const printWrapper = document.querySelector(
    ".printwrapper"
  ) as HTMLDivElement;


  const elements: HTMLElement[] = Array.from(
    document.querySelectorAll(".printcontainer")
  );


  function downloadPDF() {
    var pdf: jsPDF = new jsPDF({
      unit: "px",
      format: "a4",
      orientation: "portrait",
    });

    Promise.all(
      elements.map(async (element) => {
        // if ( typeof(element)) = HTMLIFrameElement ){

        // }
        console.log(typeof element);
        const canvas = await html2canvas(element, {
          scale: 2,
          // width: 1280,
          // windowWidth: 1260 / 2,
          removeContainer: false,
        });

        element.replaceWith(canvas);
      })
    ).then(() => {
      pdf.html(document.querySelector(".printcontainer") as HTMLElement, {
        callback: (generatedPdf) => {
          generatedPdf.output("dataurlnewwindow");
          //  generatedPdf.save("test.pdf");
        },
        margin: [0, 0, 0, 0],
      });
    });
  }
  */
  return (
    <div>
      <h1>Services</h1>

      <Portfolio />
      {displayResume && (
        <Stack>
          <a
            href={resume}
            target="_blank"
            rel="noreferrer"
            style={{
              alignSelf: "flex-end",
              display: "flex",
              marginLeft: "40px",
              marginBottom: '-4px'
            }}
          >
            <button
              style={{
                backgroundColor: "var(--defaultBlue)",
                borderRadius: "10px",
                borderBottomRightRadius: "0",
                borderBottomLeftRadius: "0",
                height: "2.5em",
                marginTop: "-10px",
                marginRight: "20px",
              }}
            >
              Download Resume
            </button>
          </a>

          <div
            className="printcontainer"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "normal",
            }}
          >
            <div className="printwrapper">
              <iframe
                style={{ marginTop: "0" }}
                title="Resume"
                src="https://technificentconsulting.github.io/"
              />
            </div>
          </div>
        </Stack>
      )}
    </div>
  );
};

export default Services;
